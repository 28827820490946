import "./styles.css";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
//import { SplitText } from "gsap/SplitText";
//import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
//import Swiper from 'swiper';
import 'swiper/css';
import mixpanel from 'mixpanel-browser';

mixpanel.init('fde5791938730e735d7f27397906dc0b');
mixpanel.track('Landing Page Visit')

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

if (screen.width > 1024) {
    // create the smooth scroller FIRST!
    document.fonts.ready.then(function () {

      setTimeout(function () {
        document.getElementById('preloader').classList.add('off');
      }, 300);
      
  
      // create the smooth scroller FIRST!
      let smoother;
      smoother = ScrollSmoother.create({
        speed: 1,
        smooth: 1,
        effects: true,
      });

      gsap.to("#hero", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top 0",
          end: "top -40%",
        },
        ease: "linear",
        scale: 6,
        filter: 'blur(10px)',
        immediateRender:false
      });

      gsap.to("#hero", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -20",
          end: "top -40%",
        },
        ease: "linear",
        autoAlpha: 0,
        immediateRender:false
      });

      gsap.to("#second", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -30%",
          end: "top -120%",
        },
        ease: "linear",
        scale: 1,
        filter: 'blur(0px)',
        autoAlpha: 1,
        immediateRender:false
      });

      gsap.to("#screen1, #screen_text_1", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -120%",
          end: "top -150%",
        },
        ease: "linear",
        x: "-23vw",
        immediateRender:false
      });
      gsap.to("#screen2, #screen_text_2", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -120%",
          end: "top -150%",
        },
        ease: "linear",
        x: "23vw",
        immediateRender:false
      });

      gsap.to("#screen_text_1", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -135%",
          end: "top -150%",
        },
        ease: "linear",
        autoAlpha: 1,
        immediateRender:false
      });
      gsap.to("#screen_text_2", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -135%",
          end: "top -150%",
        },
        ease: "linear",
        autoAlpha: 1,
        immediateRender:false
      });

      gsap.to("#second", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -160%",
          end: "top -190%",
        },
        ease: "linear",
        scale: 6,
        filter: 'blur(10px)',
        autoAlpha: 0,
        immediateRender:false
      });

      gsap.to("#third", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -160%",
          end: "top -280%",
        },
        ease: "linear",
        scale: 1,
        filter: 'blur(0px)',
        autoAlpha: 1,
        immediateRender:false
      });

      gsap.to("#third-1", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -280%",
          end: "top -310%",
        },
        ease: "linear",
        autoAlpha: 1,
        immediateRender:false
      });
      gsap.to("#third-2", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -310%",
          end: "top -340%",
        },
        ease: "linear",
        autoAlpha: 1,
        immediateRender:false
      });
      gsap.to("#third-3", {
        scrollTrigger: {
          trigger: "#height",
          scrub: 0.2,
          start: "top -340%",
          end: "top -370%",
        },
        ease: "linear",
        autoAlpha: 1,
        immediateRender:false
      });
      gsap.to("#opening", {
        scrollTrigger: {
          trigger: "#white",
          scrub: 0.2,
          start: "top 80%",
          end: "top -20%",
        },
        ease: "power4.InOut",
        width: "900vw",
        immediateRender:false
      });

      ScrollTrigger.create({
        trigger: "#white",
        start: "top 3%",
        end: "bottom -1000%",
        toggleClass: {targets: "#logo, #buttons", className: 'black'},
      });

      gsap.to("#white", {
        scrollTrigger: {
          trigger: "#white",
          scrub: 0.2,
          start: "top 0",
          end: "top -10%",
        },
        background: '#E6E6E6',
        immediateRender:false
      });

      gsap.to(".cost-item.one", {
        scrollTrigger: {
          trigger: "#cost-items",
          scrub: 0.2,
          start: "top 90%",
          end: "top 60%",
        },
        ease: "power4.InOut",
        autoAlpha: 1,
        immediateRender:false
      });
      gsap.to(".cost-item.two", {
        scrollTrigger: {
          trigger: "#cost-items",
          scrub: 0.2,
          start: "top 80%",
          end: "top 50%",
        },
        ease: "power4.InOut",
        autoAlpha: 1,
        immediateRender:false
      });
      gsap.to(".cost-item.three", {
        scrollTrigger: {
          trigger: "#cost-items",
          scrub: 0.2,
          start: "top 70%",
          end: "top 40%",
        },
        ease: "power4.InOut",
        autoAlpha: 1,
        immediateRender:false
      });

      ScrollTrigger.create({
        trigger: "#inderectbox",
        start: "center 70%",
        toggleClass: {targets: "#inderectbox", className: 'on'},
      });

      ScrollTrigger.create({
        trigger: "#eight",
        start: "top 3%",
        end: "bottom 3%",
        onEnter: () => {
            // Al entrar en la vista, quitar la clase
            document.querySelector("#logo").classList.remove("black");
            document.querySelector("#buttons").classList.remove("black");
        },
        onEnterBack: () => {
            // Al volver a entrar en la vista, quitar la clase
            document.querySelector("#logo").classList.remove("black");
            document.querySelector("#buttons").classList.remove("black");
        },
        onLeave: () => {
            // Al salir de la vista, agregar la clase
            document.querySelector("#logo").classList.add("black");
            document.querySelector("#buttons").classList.add("black");
        },
        onLeaveBack: () => {
            // Al volver a salir de la vista, agregar la clase
            document.querySelector("#logo").classList.add("black");
            document.querySelector("#buttons").classList.add("black");
        },
        immediateRender: false
    });

    ScrollTrigger.create({
      trigger: "#seven",
      start: "top 0",
      end: "top -100%",
      scrub: .2,
      pin: "#seven",
      pinSpacing: false,
    });

    ScrollTrigger.create({
      trigger: "#eight",
      start: "top 0",
      end: "top -100%",
      scrub: .2,
      pin: "#eight h2",
      pinSpacing: false,
    });

    smoother.effects("#testimonials2", {speed: 1.1});
    
    ScrollTrigger.create({
      trigger: "#nine",
      start: "top 0",
      end: "top -100%",
      scrub: .2,
      pin: "#nine",
      pinSpacing: true,
    });

    gsap.to("#mockup", {
      scrollTrigger: {
        trigger: "#start",
        scrub: 0.2,
        start: "top 100%",
        end: "top 0",
      },
      ease: "power4.InOut",
      y: -innerWidth*0.1,
      immediateRender:false
    });

    function numero1() {
      gsap.from('.number.one span', {
        textContent: 2700,
        duration: 3,
        ease: "power1.Out",
        modifiers: {
          textContent: value => {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue)) {
              return Math.round(numericValue).toLocaleString('en-US');
            } else {
              return value;
            }
          }
        },
      });
      gsap.from('.number.two span', {
        textContent: 1650,
        duration: 3,
        ease: "power1.Out",
        modifiers: {
          textContent: value => {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue)) {
              return Math.round(numericValue).toLocaleString('en-US');
            } else {
              return value;
            }
          }
        },
      });
      gsap.from('.number.three span', {
        textContent: 200,
        duration: 3,
        ease: "power1.Out",
        modifiers: {
          textContent: value => {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue)) {
              return Math.round(numericValue).toLocaleString('en-US');
            } else {
              return value;
            }
          }
        },
      });

    }
    

    let numero1Ejecutada = false;

    ScrollTrigger.create({
      trigger: ".dataitem",
      start: "top 100%",
      end: "top -200%",
      scrub: .2,
      onEnter: () => {
        if (!numero1Ejecutada) {
          numero1();
          numero1Ejecutada = true;
        }
      },
      toggleActions: "play none none none" // Esto asegura que la función se ejecute solo una vez
    });

    const extensibles = document.querySelectorAll(".extensible");

    if (extensibles) {
      extensibles.forEach((element) => {
        element.addEventListener("click", function () {
          if (this.classList.contains("open")) {
            this.classList.remove("open");
            setTimeout(() => {
             
            }, 500);
          } else {
            extensibles.forEach((el) => el.classList.remove("open"));
            this.classList.add("open");
            setTimeout(() => {
            
            }, 500);
          }
        });
      });
    }

    gsap.to("#starter", {
      scrollTrigger: {
        trigger: "#eight",
        scrub: 0.2,
        start: "top 0",
        end: "top -10%",
      },
      ease: "linear",
      autoAlpha: 0,
      immediateRender:false
    });
    gsap.to("#final", {
      scrollTrigger: {
        trigger: "#eight",
        scrub: 0.2,
        start: "top 0",
        end: "top -10%",
      },
      ease: "linear",
      autoAlpha: 1,
      immediateRender:false
    });

    ScrollTrigger.create({
      trigger: "#finalspace",
      start: "top 3%",
      end: "bottom 3%",
      onEnter: () => {
          // Al entrar en la vista, quitar la clase
          document.querySelector("#logo").classList.remove("black");
          document.querySelector("#buttons").classList.remove("black");
      },
      onEnterBack: () => {
          // Al volver a entrar en la vista, quitar la clase
          document.querySelector("#logo").classList.remove("black");
          document.querySelector("#buttons").classList.remove("black");
      },
      onLeave: () => {
          // Al salir de la vista, agregar la clase
          document.querySelector("#logo").classList.add("black");
          document.querySelector("#buttons").classList.add("black");
      },
      onLeaveBack: () => {
          // Al volver a salir de la vista, agregar la clase
          document.querySelector("#logo").classList.add("black");
          document.querySelector("#buttons").classList.add("black");
      },
      immediateRender: false
  });

  var totop = document.getElementById("backtotop");
  totop.addEventListener('click', function () {
    gsap.to(smoother, {
      // don't let it go beyond the maximum scrollable area
      scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.offset('#height', '0')),
      duration: 4
    });;
  });


    });

};
////////////////////////////////////////////////////////RESPONSIVE
if (screen.width < 1024) {
  // create the smooth scroller FIRST!
  document.fonts.ready.then(function () {

    setTimeout(function () {
      document.getElementById('preloader').classList.add('off');
    }, 300);

    // create the smooth scroller FIRST!
    let smoother;
    smoother = ScrollSmoother.create({
      speed: 1,
      smooth: 1,
      effects: true,
    });

    gsap.to("#hero", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top 0",
        end: "top -40%",
      },
      ease: "linear",
      scale: 6,
      filter: 'blur(10px)',
      immediateRender:false
    });

    gsap.to("#hero", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -20",
        end: "top -40%",
      },
      ease: "linear",
      autoAlpha: 0,
      immediateRender:false
    });

    gsap.to("#second", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -30%",
        end: "top -120%",
      },
      ease: "linear",
      scale: 1,
      filter: 'blur(0px)',
      autoAlpha: 1,
      immediateRender:false
    });

    gsap.to("#screen1, #screen_text_1", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -120%",
        end: "top -150%",
      },
      ease: "linear",
      y: "-40vw",
      immediateRender:false
    });
    gsap.to("#screen2, #screen_text_2", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -120%",
        end: "top -150%",
      },
      ease: "linear",
      y: "40vw",
      immediateRender:false
    });
    gsap.to("#screen_text_1, #screen_text_2", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -135%",
        end: "top -150%",
      },
      autoAlpha: 1,
      immediateRender:false
    });
  
    gsap.to("#second", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -160%",
        end: "top -190%",
      },
      ease: "linear",
      scale: 6,
      filter: 'blur(10px)',
      autoAlpha: 0,
      immediateRender:false
    });

    gsap.to("#third", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -160%",
        end: "top -280%",
      },
      ease: "linear",
      scale: 1,
      filter: 'blur(0px)',
      autoAlpha: 1,
      immediateRender:false
    });

    gsap.to("#third-1", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -280%",
        end: "top -310%",
      },
      ease: "linear",
      autoAlpha: 1,
      immediateRender:false
    });
    gsap.to("#third-2", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -310%",
        end: "top -340%",
      },
      ease: "linear",
      autoAlpha: 1,
      immediateRender:false
    });
    gsap.to("#third-3", {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top -340%",
        end: "top -370%",
      },
      ease: "linear",
      autoAlpha: 1,
      immediateRender:false
    });
    gsap.to("#opening", {
      scrollTrigger: {
        trigger: "#white",
        scrub: 0.2,
        start: "top 80%",
        end: "top -20%",
      },
      ease: "power4.InOut",
      width: "900vw",
      immediateRender:false
    });

    ScrollTrigger.create({
      trigger: "#white",
      start: "top 3%",
      end: "bottom -1000%",
      toggleClass: {targets: "#logo, #buttons", className: 'black'},
    });

    gsap.to("#white", {
      scrollTrigger: {
        trigger: "#white",
        scrub: 0.2,
        start: "top 50%",
        end: "top 40%",
      },
      background: '#E6E6E6',
      immediateRender:false
    });

    ScrollTrigger.create({
      trigger: "#inderectbox",
      start: "center 70%",
      toggleClass: {targets: "#inderectbox", className: 'on'},
    });

    ScrollTrigger.create({
      trigger: "#eight",
      start: "top 3%",
      end: "bottom 3%",
      onEnter: () => {
          // Al entrar en la vista, quitar la clase
          document.querySelector("#logo").classList.remove("black");
          document.querySelector("#buttons").classList.remove("black");
      },
      onEnterBack: () => {
          // Al volver a entrar en la vista, quitar la clase
          document.querySelector("#logo").classList.remove("black");
          document.querySelector("#buttons").classList.remove("black");
      },
      onLeave: () => {
          // Al salir de la vista, agregar la clase
          document.querySelector("#logo").classList.add("black");
          document.querySelector("#buttons").classList.add("black");
      },
      onLeaveBack: () => {
          // Al volver a salir de la vista, agregar la clase
          document.querySelector("#logo").classList.add("black");
          document.querySelector("#buttons").classList.add("black");
      },
      immediateRender: false
  });

  smoother.effects("#testimonials2", {speed: 1.1});

  function numero1() {
    gsap.from('.number.one span', {
      textContent: 2700,
      duration: 3,
      ease: "power1.Out",
      modifiers: {
        textContent: value => {
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            return Math.round(numericValue).toLocaleString('en-US');
          } else {
            return value;
          }
        }
      },
    });
    gsap.from('.number.two span', {
      textContent: 1650,
      duration: 3,
      ease: "power1.Out",
      modifiers: {
        textContent: value => {
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            return Math.round(numericValue).toLocaleString('en-US');
          } else {
            return value;
          }
        }
      },
    });
    gsap.from('.number.three span', {
      textContent: 200,
      duration: 3,
      ease: "power1.Out",
      modifiers: {
        textContent: value => {
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            return Math.round(numericValue).toLocaleString('en-US');
          } else {
            return value;
          }
        }
      },
    });

  }
  

  let numero1Ejecutada = false;

  ScrollTrigger.create({
    trigger: ".dataitem",
    start: "top 100%",
    end: "top -200%",
    scrub: .2,
    onEnter: () => {
      if (!numero1Ejecutada) {
        numero1();
        numero1Ejecutada = true;
      }
    },
    toggleActions: "play none none none" // Esto asegura que la función se ejecute solo una vez
  });

  const extensibles = document.querySelectorAll(".extensible");

  if (extensibles) {
    extensibles.forEach((element) => {
      element.addEventListener("click", function () {
        if (this.classList.contains("open")) {
          this.classList.remove("open");
          setTimeout(() => {
           
          }, 500);
        } else {
          extensibles.forEach((el) => el.classList.remove("open"));
          this.classList.add("open");
          setTimeout(() => {
          
          }, 500);
        }
      });
    });
  }

  gsap.to("#starter", {
    scrollTrigger: {
      trigger: "#eight",
      scrub: 0.2,
      start: "top 0",
      end: "top -10%",
    },
    ease: "linear",
    autoAlpha: 0,
    immediateRender:false
  });
  gsap.to("#final", {
    scrollTrigger: {
      trigger: "#eight",
      scrub: 0.2,
      start: "top 0",
      end: "top -10%",
    },
    ease: "linear",
    autoAlpha: 1,
    immediateRender:false
  });

  ScrollTrigger.create({
    trigger: "#finalspace",
    start: "top 3%",
    end: "bottom 3%",
    onEnter: () => {
        // Al entrar en la vista, quitar la clase
        document.querySelector("#logo").classList.remove("black");
        document.querySelector("#buttons").classList.remove("black");
    },
    onEnterBack: () => {
        // Al volver a entrar en la vista, quitar la clase
        document.querySelector("#logo").classList.remove("black");
        document.querySelector("#buttons").classList.remove("black");
    },
    onLeave: () => {
        // Al salir de la vista, agregar la clase
        document.querySelector("#logo").classList.add("black");
        document.querySelector("#buttons").classList.add("black");
    },
    onLeaveBack: () => {
        // Al volver a salir de la vista, agregar la clase
        document.querySelector("#logo").classList.add("black");
        document.querySelector("#buttons").classList.add("black");
    },
    immediateRender: false
});

gsap.to("#mockup", {
  scrollTrigger: {
    trigger: "#mockup",
    scrub: 0.2,
    start: "top 100%",
    end: "bottom 0",
  },
  ease: "power4.InOut",
  y: -innerWidth*0.2,
  immediateRender:false
});

var totop = document.getElementById("backtotop");
totop.addEventListener('click', function () {
  gsap.to(smoother, {
    // don't let it go beyond the maximum scrollable area
    scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.offset('#height', '0')),
    duration: 4
  });;
});


  });

};