import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import {Float } from "@react-three/drei";
import App from "./Model.jsx";
import { OrbitControls, Environment } from '@react-three/drei'
import { Bloom, DepthOfField, EffectComposer, Noise, Vignette } from '@react-three/postprocessing'

    document.fonts.ready.then(function () {

    const root = createRoot(document.getElementById("root"));

    root.render(
        <>

        
        
        <App></App>
     


        </>
    );

    });