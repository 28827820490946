import { useState, useRef, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Points, PointMaterial, OrbitControls } from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function App() {
  return (
    <Canvas  
    
      camera={{
      near: .2,
      far: 2,
      position: [0, 0, 1],
      }}

      >
      <Stars />
    </Canvas>
  )
}

function Stars(props) {

  const constelacion = useRef()

  useEffect(() => {

    gsap.to(constelacion.current.position, {
      scrollTrigger: {
        trigger: "#height",
        scrub: 0.2,
        start: "top 0",
        end: "top -120%",
      },
      ease: "power1.Out",
      z: 0,
      immediateRender:false
    });

    gsap.to(constelacion.current.position, {
      scrollTrigger: {
        trigger: "#height",
          scrub: 0.2,
          start: "top -160%",
          end: "top -280%",
      },
      ease: "power1.Out",
      z: 2,
      immediateRender:false
    });

  }, []);

  const ref = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(40000), { radius: 3 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 200
    ref.current.rotation.y -= delta / 350
  })
  return (
    <group rotation={[0, 0, Math.PI / 4]} position={[0,0,-2]} ref={constelacion}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial transparent color="#ffffff" size={0.003} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}
